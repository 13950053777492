import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [2];

export const dictionary = {
		"/(auth)": [~8,[2]],
		"/(auth)/account": [21,[2]],
		"/(no-auth)/(glowing-bg)/account/reset-password": [28,[7]],
		"/(no-auth)/(glowing-bg)/account/reset-password/confirm": [29,[7]],
		"/(no-auth)/(glowing-bg)/account/sign-in": [~30,[7]],
		"/(no-auth)/(glowing-bg)/account/sign-up": [31,[7]],
		"/(no-auth)/(glowing-bg)/account/verify-email": [32,[7]],
		"/(auth)/(admin)/admin/api-docs": [9,[2,3]],
		"/(auth)/(admin)/admin/backend": [10,[2,3]],
		"/(auth)/(admin)/admin/overview": [11,[2,3]],
		"/(auth)/(admin)/admin/search-jobs": [12,[2,3]],
		"/(no-auth)/(glowing-bg)/demo": [33,[7]],
		"/(auth)/(create-ghost)/for-you": [~13,[2]],
		"/(auth)/(create-ghost)/for-you/(onboarding)/complete-resume": [~14,[2,4]],
		"/(auth)/(create-ghost)/for-you/(onboarding)/job-preferences": [15,[2,4]],
		"/(auth)/(create-ghost)/for-you/(onboarding)/review-jobs": [16,[2,4]],
		"/(auth)/(create-ghost)/for-you/(onboarding)/select-resume": [17,[2,4]],
		"/(auth)/(create-ghost)/for-you/(onboarding)/start": [18,[2,4]],
		"/(auth)/(create-ghost)/jobs": [19,[2]],
		"/(auth)/(create-ghost)/jobs/[slug]": [20,[2]],
		"/(auth)/profile/job-preferences": [22,[2,5]],
		"/(auth)/profile/my-profile": [23,[2,5]],
		"/(auth)/profile/my-profile/resumes/new": [25,[2,5,6]],
		"/(auth)/profile/my-profile/resumes/[id]": [24,[2,5,6]],
		"/(auth)/profile/qualifications": [26,[2,5]],
		"/(no-auth)/quickstart": [35],
		"/(no-auth)/sentry/test": [36],
		"/(auth)/support": [27,[2]],
		"/(no-auth)/(glowing-bg)/welcome": [34,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';